
<template>
    <div class="items">
    <h1>Items</h1>
    <table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Options</th>
                    <th>Categories</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in items" :key="item.id">
                    <td>{{item.id}}</td>
                    <td><router-link :to='{path: "/admin/items/" + item.id}'>{{item.name}}</router-link></td>
                    <td>{{item.description}}</td>
                    <td><span v-for="option in item.options" :key="option.id"><router-link :to='{path: "/admin/options/" + option.id}'>{{option.name}}</router-link>, </span></td>
                    <td><span v-for="category in item.categories" :key="category.id"><router-link :to='{path: "/admin/categories/" + category.id}'>{{category.name}}</router-link>, </span></td>
                </tr>
            </tbody>
    </table>
    </div>
</template>

<script>
export default {
  name: 'Items',
  props: ['items'],
}
</script>
<style>


td, th {
  border: 1px solid #999;
  padding: 0.5rem;
}


</style>
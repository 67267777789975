<template>
  <div class="home">
    <p><button @click="newItem">New Item</button></p>
    <Items :items="items"></Items>
  </div>
</template>

<script>
import Items from '@/components/admin/Items.vue'
export default {
  name: 'Home',
  data() {
    let that = this;
    let items= this.$root.items;
    items.forEach(function (item) {
        item.options= that.$root.options.filter((option)=>that.$root.item_options.filter(({item_id})=> item_id==item.id).some((item_option)=>item_option.option_id==option.id));
        item.categories = that.$root.categories.filter((category)=>that.$root.category_items.filter(({item_id})=>item_id == item.id).some((category_item)=>category_item.category_id == category.id));
    })
    return {
      items: items
    }
  },
  components: {
    Items,
  },
  methods: {
    newItem() {
      this.$root.i+=1;
      let item = {id: this.$root.i, name:""};
      this.$root.items.push(item);
      this.$router.push({path:'/admin/items/'+item.id})
    }
  }
}
</script>
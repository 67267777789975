<template>
    <h1>Statuses</h1>
    <h2>Warning: altering these names will affect all orders that use them.</h2>
    <p>Each name and order value must be unique.</p>
    <p>If you change the order you'll need to refresh the page after they're saved.</p>
    <ol>
        <li v-for="status in statuses" :key="status.id">
            <div v-if="status == editstatus">
                <p>Name:<input type="text" v-model="editstatus.status"/></p>
                <p>Order:<input type="number" min="0" v-model="editstatus.order"/></p>
                <a @click="cancel()">Cancel</a> | <a @click="save()">Save</a>
            </div>
            <div v-else>
                <h3>{{status.order}} - {{status.status}}</h3>
                <a @click="edit(status)">Edit</a>
            </div>
        </li>
    </ol>
    <div v-if="donew">
        <h3>New Status</h3>
        <p>Values must be unique from others.</p>
        <p>Name:<input type="text" v-model="newstatus.status"/></p>
        <p>Order:<input type="number" min="0" v-model="newstatus.order"/></p>
        <a @click="donew=false">Cancel</a> | <a @click="create">Save</a>
    </div>
    <div v-else>
        <a @click="donew=true">New</a>
    </div>
    
</template>

<script>
export default {
    props:['id'],
    data() {
        //statuses = [];
        //for (const status in this.$root.statuses) {
        console.log(this.$root);
        return {
            copystatus :{},
            editstatus: {},
            newstatus: {
                status:"",
                order:0
            },
            donew:false
        }
    },
    computed: {
        statuses() {
            return this.$root.statusesArray
        }
    },
    methods:{
        edit(status){
            Object.assign(this.copystatus,status);
            this.editstatus = status;
        },
        cancel() {
            Object.assign(this.editstatus,this.copystatus);
            this.editstatus= {};
        },
        create() {
            fetch(this.$root.url+"/statuses", {
                method:"POST", 
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify({status:this.newstatus.status,order:this.newstatus.order})
            })
           .then(response => response.json())
            .then(data => {
                    this.$root.statuses[data.id] = data;
                    this.$root.statusesArray.push(data);
                    this.newstatus = {};
            })
            .catch(function(error) {
                console.log('Looks like there was a problem: ', error);
            });
        },
        save() {
            fetch(this.$root.url+"/statuses", {
                method:"PUT", 
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json',
                },
                    body:JSON.stringify(this.editstatus)
            })
            .then((response) => {
                if (response.ok) {
                    this.editstatus= {}
                }
            })
            .catch(function(error) {
                console.log('Looks like there was a problem: ', error);
            });
        }
    },
}
</script>
<template>
<div>
    <p>Name:<input type="text" v-model="item.name" /></p>
    <p>Description:<input type="text" v-model="item.description" /></p>
    <p><button @click="show_categories=!show_categories">Choose Categories</button></p>
    <div v-if="show_categories">
    <div v-for="category in categories" :key="'category'+category.id">
        <input type="checkbox" :id="'category'+category.id" :value="category" v-model="item.categories" />
        <label :for="'category'+category.id">{{category.name}}</label>
    </div>
    </div>
    <p><button @click="show_options=!show_options">Choose Options</button></p>
    <div v-if="show_options">
    <div v-for="option in options" :key="'option'+option.id">
        <input type="checkbox" :id="option.id" :value="option" v-model="item.options" />
        <label :for="option.id">{{option.name}}</label>
    </div>
    </div>
    <p><button @click="save(false)">Save</button></p>
    <p><button @click="save(true)">Save and Duplicate</button></p>
</div>
</template>
<script>


//import ChooseItems from '@/components/ChooseItems.vue'
//import TextOption from '@/components/TextOption.vue'

export default {
    props: ['id'],
    data() {
        let categories = this.$root.categories;
        let options = this.$root.options;
        let item= {...this.$root.items.find(({id})=>id==this.id)};
        item.options = options.filter((option)=>this.$root.item_options.filter(({item_id})=> item_id==this.id).some((item_option)=>item_option.option_id==option.id));
        item.categories = this.$root.categories.filter(category=> this.$root.category_items.filter(({item_id})=>item_id==item.id).some(category_item => category_item.category_id == category.id));
        return {
            show_options: false,
            show_categories: false,
            options: options,
            item: item,
            categories: categories,
        }
    },
    emits: ['update:existing'],
    watch:
    {
        // item:
        // {
        //     handler: function (){
        //         if (this.type=="part") {
        //             console.log("emitting");
        //             this.$emit('update:existing',this.$data.item);
        //         }
        //     },
        //     deep:true
        // },
    },
    methods: {
        // add(item) {
        //     this.$store.commit('add', item);
        //     this.$router.push({name:'Cart'})
        // },
        // replace(item) {
        //     this.$store.commit('replace', item);
        //     this.$router.push({name:'Cart'})
        // },
        // addItemOption: function(option,id) {
        //     option.items.push({id:id})
        // },
        // removeItemOption: function(option, index) {
        //     option.items.splice(index,1);
        // }
        save(duplicate) {
            //update item
            let i = this.$root.items.findIndex(item=>item.id==this.item.id);
            this.$root.items[i].name = this.item.name;
            this.$root.items[i].description = this.item.description;

            //update item_options and item_categories
            //remove existing options and categories
            let o = 0;
            while (o<this.$root.item_options.length) {
                if (this.$root.item_options[o].item_id == this.id) {
                    this.$root.item_options.splice(o,1);
                } else {
                    o++;
                }
            }
            let c = 0;
            while (c<this.$root.category_items.length) {
                if (this.$root.category_items[c].item_id == this.id) {
                    this.$root.category_items.splice(c,1);
                } else {
                    c++;
                }
            }
            //add new options and categories
            this.item.options.forEach((option) => {
                this.$root.item_options.push({item_id:this.id, option_id:option.id});
            });
            this.item.categories.forEach((category) => {
                this.$root.category_items.push({item_id:this.id, category_id:category.id});
            });


            if (duplicate) {
                let new_id = this.$root.i++;
                this.item.id = new_id;    
                console.log(new_id);       
                this.item.name = "~"+this.item.name+"~";     
                this.item.options.forEach((option) => {
                    this.$root.item_options.push({item_id:this.id, option_id:option.id});
                });

                this.$router.push({path:'/admin/items/'+new_id})
            } else {
                this.$router.push({path:'/admin/items'})
            }
        }
    },
    components: {
        //ChooseItems,
        //TextOption
    }
}
</script>
<template>
  <div >
  <h1>Orders</h1>
    <Orders :orders="orders" :users="true"></Orders>
</div>
</template>

<script>
import Orders from '@/components/Orders.vue'
export default {
  data() {
      return {
          orders:[]
      }
  },
   created () {
      fetch(this.$root.url+"/orders/all",{
          credentials:"include"
      })
      .then(response => response.json())
        .then(data => {
            this.orders = data;
        })
        .catch(function(error) {
            console.log('Looks like there was a problem: ', error);
        });
   },
  components: {
    Orders,
  },
}
</script>
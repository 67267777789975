<template>
<div>
<div v-if="!edit">
    <div v-if="order">
        <h1>Order - {{order.order.description}}</h1>
        <p>Ordered on {{order.order.created}}</p>
        <h2>Invoices</h2>
        <ol>
            <li v-for="invoice in order.invoices" :key="invoice.id">
                <h3>{{invoice.description}}</h3>
                <div v-if="invoice.remaining==0">
                    <p>Invoice Paid</p>
                </div>
                <div v-else>
                    <p>Amount: {{invoice.amount}}</p>
                    <p>Due Date: {{invoice.due_date}}</p>
                    <p>Paid: {{invoice.amount-invoice.remaining}}</p>
                </div>
            </li>
        </ol>
        <CreateInvoice v-if="create_invoice" @cancel="create_invoice=false" @addInvoice="(invoice) => order.invoices.push(invoice)" :orderid="order.order.id" />
        <button v-else type="button" @click="create_invoice = true">Add Invoice</button>
        <div v-if="order.payments.length>0">
            <h2>Payments</h2>
            <li v-for="payment in order.payments" :key="payment.id">
                <h3>{{formatter.format(payment.amount)}}</h3>
                <p>{{payment.description}}</p>
                <div v-if="payment.paid">
                    <p>Paid on {{payment.paid_date}}</p>
                </div>
                <div v-else-if="!payment.locked">
                    <p v-if="payment.due_date">Due on {{payment.due_date}}</p>
                    <p>Pay now.</p>
                </div>
                <div v-else>
                    <p>Not available to be paid at this time.</p>
                </div>
            </li>
        </div>
        <h2>Updates</h2>
        <ol>
            <li v-for="update in order.updates" :key="update.id">
                <h3 v-if="statuses[update.status_id]">{{statuses[update.status_id].status}} - {{update.title}}</h3>
                <p>{{update.created}}</p>
            </li>
        </ol>
        <OrderUpdate v-if="create_update" :orderid="order.order.id" />
         <button v-else type="button" @click="create_update = true">Add Update</button>
         <button v-if="!notified" type="button" @click="notify">Send Notification</button>
    </div>
</div>
<div v-else>
    <h1 v-if="order.id">Edit Order</h1>
    <h1 v-else>Create Order</h1>
    <form @submit.prevent="submit">
        <ul>
            <li><p>{{message}}</p></li>
            <li><label for="order_userid">User Id:</label><input type="number" id="order_userid" v-model.number="editOrder.user_id" required/></li>
            <li><label for="order_description">Description:</label><input type="text" id="order_description" v-model="editOrder.description" required/></li>
            <li v-if="id"><button type="submit">Save</button></li>
            <li v-else><button type="submit">Create</button></li>
        </ul>
    </form>
    <p v-if="order.id"><a @click="edit=false">Cancel</a></p>
</div>
</div>
</template>

<script>
import OrderUpdate from '@/components/admin/OrderUpdate.vue'
import CreateInvoice from '@/components/admin/CreateInvoice.vue'
export default {
    props:['id','userid'],
    
    data() {
         var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });
        return {
            order:false,
            create_invoice: false,
            create_update: false,
            editOrder: {},
            formatter: formatter,
            message: "",
            edit: false,
            statuses: this.$root.statuses,
            notified: false,
        }
    },
    mounted () {
        if (this.id) {
            fetch(this.$root.url+"/orders/"+this.id,{
                credentials:"include"
            })
            .then(response => response.json())
            .then(data => {
                this.order = data;
            })
            .catch(function(error) {
                console.log('Looks like there was a problem: ', error);
            });
        } else {
            if (this.userid) {
                this.editOrder.user_id = Number(this.userid);
            }
            this.edit=true;
        }
    },
    methods: {
        notify() {
            this.notified= true;
            fetch(this.$root.url+"/orders/"+this.id+"/notify", {
                method:"GET", 
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .catch(error =>{
                this.notified=false;
                console.error('EXCEPTION: ', error)
            });
        },
        submit() {
            this.message="Loading...";
            fetch(this.$root.url+"/orders/", {
                method:"POST", 
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify(this.editOrder)
            })
            .then(response => response.json())
            .then(data => {
                this.order.order = data;
                this.edit=false;
            })
            .catch(error =>{
                this.message = "Connection or Server Error";
                console.error('EXCEPTION: ', error)
            });
        }
    },
    components: {
        OrderUpdate,
        CreateInvoice,
    }
}
</script>
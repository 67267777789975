<template>
  <div>
    <h1>Admin</h1>
    <div id="adminnav">
    <router-link v-if="false" to="/admin">Home</router-link>
    <router-link v-if="false" to="/admin/items">Items</router-link>
    <router-link v-if="false" to="/admin/categories">Categories</router-link>
    <router-link v-if="false" to="/admin/options">Options</router-link>
    <router-link to="/admin/orders">Orders</router-link> |
    <router-link to="/admin/statuses">Statuses</router-link> |
    <router-link to="/admin/users">Users</router-link>
    </div>
    <router-view></router-view>
  </div>
</template>

<template>
  <header>
  <nav>
    <section>
      <ul>
          <li v-if="!user.email"><router-link to="/">Home</router-link></li>
        <li v-if="!user.email"><router-link to="/login">Login</router-link></li>
        <li v-if="user.email"><router-link to="/">Home</router-link></li>
        <li v-if="user.role=='admin'"><router-link to="/about">About</router-link></li>
        <li v-if="user.role=='admin'"><router-link to="/admin">Admin</router-link></li>
      </ul>
    </section>
    <section>
      <ul>
        <li v-if="user.role=='admin'"><router-link to="/cart">Cart</router-link></li>
        <li v-if="user.email"><router-link to="/orders">Orders</router-link></li>
        <li v-if="false"><router-link to="/join">Join</router-link></li>
    
        <li v-if="user.email"><router-link to="/logout">Logout</router-link></li>
      </ul>
    </section>
  </nav>
  </header>
  <main class="standard">
  <router-view/>
  </main>
  <footer>
  <p id="copyright">Copyright 2022 Falcon Cabinets, LLC</p>
  </footer>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Shippori+Antique+B1&display=swap');
html, body {
    height: 100%;
}

body {
  font-family: 'Shippori Antique B1', sans-serif;
  background: #FFF;
  margin:0px;
  padding:0px;
  color: #89674E;
}
#app {
           display: flex;
  flex-direction: column;
  height: 100vh;
}
ul {
    margin:0px;
  padding:0px;
}
header {
  position:fixed;
  top:0px;
  width:100%;
}
header a {

  color:white;
}
nav {
  display:flex;
  flex-flow: row wrap;
  justify-content:space-between;
}
nav section {

}
nav section ul {
  list-style-type: none;
  display:flex;
  flex-flow:row wrap;
  justify-content:flex-start;

}
nav section ul li {
  padding:.5em;
    background-color:#89674E;
}
main {
 flex: 1 0 auto;
 display:flex;
 padding:32px 0px;
}
#adminnav {
  background-color:#89674E;
  color:white;
}
#adminnav a {
  color:white;
}
input {
  color: #89674E;

}
form {
    width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}
form li {
  justify-content:flex-end;
}
form a {
  padding-top:20px;
}
button {
  background: #89674E;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width:50%;
}

button:hover {
  filter: contrast(115%);
}

button:disabled {
  opacity: 0.5;
  cursor: default;
}
footer {
  text-align:center;
  flex-shrink: 0;
}

.home {
 text-align:center;
 width:768px;
 margin:auto;
}
.standard {
  width:768px;
  margin: 0 auto;
  padding:20px;
}

@media only screen and (max-width: 768px) {
  .standard, .home {
    width: 90%;
    margin:0px;
    margin-top:60px;
    padding:0 5%;
  }
  .standard {
    margin-top:30px;
  }
  nav  {
    background-color:#89674E;
  }
  form {
    min-width:0;
    width:90%;
    padding:5%;
  }
}

</style>
<script>

export default {
  computed: {
    user(){ return this.$store.state.user}
  },
  data() {
    return {
        url:process.env.VUE_APP_SERVER,
        order_i:3,
        order_item_i:11,
        statuses: {},
        statusesArray: [],
        order_items: [
        //   {id: 1, order_id: 1, item_id: 2},
        //   {id: 2, order_id: 1, item_id: 5,    parent_id: 1, option_id: 1},
        //   //{id: 13, order_id: 1, item_id: 6,    parent_id: 1, option_id: 1},
        //   {id: 14, order_id: 1, parent_id: 1, option_id: 2, value:"Happy Birthday!"},
        //   {id: 3, order_id: 2, item_id: 7},
        //   {id: 4, order_id: 2, item_id: 9,    parent_id: 3, option_id: 3},
        //   {id: 5, order_id: 2, item_id: 13,   parent_id: 3, option_id: 4},
        //   {id: 6, order_id: 2, item_id: 10,   parent_id: 5, option_id: 5},
        //   {id: 7, order_id: 2, value: "Hello", parent_id: 5, option_id: 6},
        //   {id: 8, order_id: 2, item_id: 13,   parent_id: 3, option_id: 4},
        //   {id: 9, order_id: 2, item_id: 11,   parent_id: 8, option_id: 5},
        //   {id: 10, order_id: 2, value: "World", parent_id: 8, option_id: 6},
        ],      
        i:15, 
        items: [],
        loading: false,
        error:null,

        //Options are used to configure items
        options: [
            // { id:1, name:"Flavor",      type: "choose", limit:1, filter_id:4},
            // { id:2, name:"Description", type: "text"    },
            // { id:3, name:"Material",    type:"choose",  filter_id:6, limit:1},
            // { id:4, name:"Engravings",  type:"add",     filter_id:13, unique:true },
            // { id:5, name:"Position",    type:"choose",  filter_id: 7, limit:1, unique:true, required:true},
            // { id:6, name:"Text",        type:"text",    limit:12, required:true},
        ],

        //Items could have more than one option each.
        //For instance, multiple backery items could have a flavor option.
        item_options: [
          {item_id: 2,  option_id:1},
          {item_id: 2,  option_id:2},
          {item_id: 7,  option_id:3},
          {item_id: 7,  option_id:4},
          {item_id: 13, option_id:5},
          {item_id: 13, option_id:6},
        ],
        
        filter: [
            // {id: 1, name:"Home Categories", type: "categories"}
        ],
        filter_selection: [
          // {filter_id: 1, category_id: 2},
          // {filter_id: 1, category_id: 5}
        ],

        fulfillments: [
          // {id: 1, name: "USPS Priority Mail"},
          // {id: 2, name: "Home Delivery"}
        ],

        category_fulfillments: [
          {id: 1, category_id:5, fulfillment_id: 1},
          {id: 2, category_id:2, fulfillment_id: 2},
          {id: 3, category_id:1, fulfillment_id: 2},
        ],
        c:9,
        categories: [
            // {id:5, name: 'Jewelery',      description:'Decorative accessories for humans.'},
            // {id:1, name: 'Apparel',      description:'Decorative accessories for humans.'},
            // {id:2, name: 'Desserts',      description:'Sweet snacks to eat after meals.'},
            // {id:4, name: 'Flavors',       description:'Used to flavor desserts.'},
            // {id:6, name: 'Material',      description:'Precious Metals.'},
            // {id:7, name: 'Positions',     description:'Location on jewelery.'},
            // {id:8, name: 'Modifications', description:'Jewelery modifications.'},
        ],
        category_items: [
            // {id:2, category_id: 2, item_id:2},
            // {id:5, category_id: 4, item_id:5},
            // {id:6, category_id: 4, item_id:6},
            // {id:7, category_id: 5, item_id:7},
            // {id:8, category_id: 6, item_id:8},
            // {id:9, category_id: 6, item_id:9},
            // {id:9, category_id: 6, item_id:14},
            // {id:10, category_id: 7, item_id:10},
            // {id:11, category_id: 7, item_id:11},
            // {id:12, category_id: 7, item_id:12},
            // {id:13, category_id: 1, item_id:7},
        ]
    }
    },
    
     created () {
      var that = this;

    fetch(this.$root.url+"/statuses")
        .then(response => response.json())
        .then(data => {
          this.statusesArray = [...data];
          data.forEach((status)=>{
            this.statuses[status.id] = status;
          })
          
        })
        .catch(function(error) {
          console.log('Looks like there was a problem: ', error);
        });
    fetch(this.url+"/user", {
          method:"GET", 
          credentials: "include",
      })
      .then(response => response.json())
      .then(data => {
              that.$store.commit('login', data);
          })
      .catch(function(error) {
          console.log('Looks like there was a problem: ', error);
      });

      fetch(this.$root.url+"/items")
       .then(response => response.json())
       .then(data => {
          that.items = data;
        })
        .catch(function(error) {
          console.log('Looks like there was a problem: ', error);
        });
      
   
      
    },
    methods: {
   
    },

}
</script>

<style>

</style>

<template>
<div class="home">
<div v-if="create">
    <h1>Join</h1>
</div>
<div v-else>
    <h1>Login</h1>
</div>
<form @submit.prevent="submit">
    <ul>
    <li><p>{{message}}</p></li>
    <li><label for="email">Email:</label><input type="email" id="email" v-model="email" required/></li>
    <div v-if="create">
        <div v-if="hasCode">
            <li><label for="code">Code:</label><input type="text" id="code" v-model="code" required/></li>
            <li><button type="submit">Login</button></li>
        </div>
        <div v-else>
            <li><button type="submit">Join and get Login Code</button></li>
        </div>
    </div>
    <div v-else>
        <div v-if="hasCode">
            <li><label for="code">Code:</label><input type="text" id="code" v-model="code" required/></li>
            <li><button type="submit">Login</button></li>
            <li><a @click="hasCode=false;message=''">I need a new code.</a></li>
            
        </div>
        <div v-else>
            <li><button type="submit">Get Login Code</button></li>
            <li><a @click="hasCode=true;message=''">I already have a code.</a></li>
        </div>
    </div>
    </ul>
</form>
</div>
</template>

<style>
.home form {
    margin:auto;
}
.home li {
    display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.home li > label {
  flex: 1 0px;
}
.home li > input {
  flex: 4 0px;
  border-radius: 6px;
  margin-bottom: 16px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}
.home li > p {
    flex:1 0px;
}
.home li > a:hover{
    cursor: pointer;
    text-decoration:underline;
}

</style>

<script>
export default {
    props: ['create'],
    data() {
        console.log(this._create);
        return {
            email:"",
            message: "",
            hasCode:false,
            code:"",
        }
    },
    methods: {
        submit() {
            var that = this;
            if (!this.hasCode) {
                let url = this.$root.url;
                if (this.create) 
                    url = url+"/request?create=true";
                else
                    url = url+"/request";
                that.message="Loading...";
                fetch(url, {
                    method:"POST", 
                    credentials: "include",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body:JSON.stringify({email: this.email})
                })
                .then((response)=>{
                    if (response.ok) {
                        that.message = "A login code has been emailed to you. It may take a few minutes.";
                        that.hasCode = true;
                    } else  {
                        that.message = "An account for that email does not exist.";
                    }
                })
                .catch(function(error) {
                    that.message = "Connection or Server Error";
                    console.error('EXCEPTION: ', error)
                });
            } else {
                that.message="Loading...";
                fetch(this.$root.url+"/login", {
                    method:"POST", 
                    credentials: "include",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                     body:JSON.stringify({email: this.email, code: this.code})
                    })
                .then(response => response.json())
                .then(data => {
                        that.$store.commit('login', data);
                        that.$router.push({name:'Orders'})
                    })
                .catch(function(error) {
                    that.message = "Connection or Server Error";
                    console.error('EXCEPTION: ', error)
                });
                
            }
        }
    }
}
</script>
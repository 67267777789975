<template>
<div class="cart_part">
    <div v-if="item.cart_id">
        <a>{{item.cart_id}}: <router-link  :to="{path: '/items/' + item.id}">{{ item.name }}</router-link></a>
    </div>
    <div v-else>
        {{ item.name }}
    </div>
    <ul v-for="option in item.options" :key="option.id">
        <li>
        {{ option.name }}
        <div v-if="option.type=='text'">
            <p>- {{option.value}}</p>
        </div>
        <div v-if="option.type=='choose'">
            <p v-for="item in option.items" :key="item.id">- {{item.name}}</p>
        </div>
        <div v-if="option.type=='add'">
            <div v-for="item in option.items" :key="item">
                <CartPart :item="item"></CartPart>
            </div>
        </div>
        </li>
    </ul>
    <div v-if="item.cart_id">
        <button @click="remove(index)">Remove</button>
        <router-link  :to="{name: 'Item', params: {'id':item.id,'cart_id':item.cart_id}}">Edit</router-link>
    </div>
</div>
</template>
<script>
    export default {
        name: 'CartPart',
        props: ['item'],
        methods: {
            remove(index) {
            console.log(index);
            this.$store.commit('remove', index);
            }
        }
    }
</script>
<style>
.cart_part {
    margin-top:2em;
    margin-left:2em;
}
</style>

<template>
    <div class="items">
    <h1>Items</h1>
    <ol>
        <li v-for="item in items" :key="item.id">
        <router-link  :to="{path: '/items/' + item.id}">{{ item.name }}</router-link>
        </li>
    </ol>
    </div>
</template>

<script>
export default {
  name: 'Items',
  props: ['items']
}
</script>

<template>
    <div class="category">
        <Items :items="items"></Items>
    </div>
</template>

<script>
import Items from '@/components/Items.vue'
 
export default {
    name: 'Category',
    props: ['id'],
    data() {
        return {
            //find items based on category_items table and category_id
            items: this.$root.items.filter(item=> this.$root.category_items.filter(({category_id})=>category_id==this.id).some(category_item => item.id== category_item.item_id))
        }
    },
    components: {
        Items
    }
}
</script>
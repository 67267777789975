<template>
    <h2>Create Update</h2>
    <form @submit.prevent="submit">
    <p>Title:<input type="text" v-model="title"/></p>
    <p>Status:
        <select v-model="status">
            <option v-for="s in statuses" :key="s.id"  :value="s.id">{{s.status}}</option>
        </select>
    </p>
    <button type="submit">Update</button>
    </form>
</template>
<script>
export default {
    props: ['orderid'],
    data() {
        console.log(this.$root.statuses);
        
        return {
            title: "",
            statuses: this.$root.statuses,
            status: Object.keys(this.$root.statuses)[0]
        }
    },
    methods: {
        submit() {
            let url = this.$root.url + "/orders/update";
            fetch(url, {
                method:"POST", 
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify({
                    title: this.title,
                    status_id: parseInt(this.status),
                    order_id: this.orderid
                })
            })
            .then((response)=>{
                if (response.ok) {
                   this.title = "";
                } else  {
                    console.log("error"); //TODO add message
                }
            })
            .catch((error)=> {
                this.message = "Connection or Server Error";
                console.error('EXCEPTION: ', error)
            });
          
        }
    }
}  
</script>      
     
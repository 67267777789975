<template>
    <form id="payment-form"  @submit.prevent="submit">
    <button v-if="!submitting" type="button" @click="cancel" id="cancel">X</button>
    <h1>Paying {{formatter.format(amount)}}</h1>
        <p v-if="message">{{message}}</p>
        <div id="payment-element">
            <!-- Elements will create form elements here -->
        </div>
        <button id="submit">Submit</button>
        <div id="error-message">
            <!-- Display error message to your customers here -->
        </div>
    </form>
</template>
<style>

#payment-form {
    text-align:center;
    position:relative;
}
#payment-form #submit {
    margin:auto;
    margin-top:20px;
}
#payment-form #cancel {
    position:absolute;
    right:10px;
    top: 10px;
    width:30px;
    text-align:center;
    padding:5px;
}

.hidden {
  display: none;
}





</style>
<script>
export default {
    props:['orderid','amount'],
    data() {
         var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });
        return {
            submitting: false,
            formatter: formatter,
            stripe: window.Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY),
            message: "",
            elements: {},
            email: this.$store.state.user.email,
            payment_intent_id: false,
            payment_amount: 0,
        }
    },
    computed:{ 
       
    },
    created () {
        this.message="Loading...";
        fetch(this.$root.url+"/payments/",{
            credentials:"include",
            method:"POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({
                payment_intent_id: "",
                order_id: parseInt(this.orderid),
                amount: parseFloat(this.amount),
            })
        })
        .then(response => response.json())
        .then((data) => {
            this.payment_intent_id = data.id;
            this.payment_amount = data.amount/100;
            if (this.payment_amount!=this.amount) {
                this.message = "Error";
                this.cancel();
            }
            const {client_secret} = data;
               const options = {
                    clientSecret: client_secret,
                    // Fully customizable with appearance API.
                    appearance: {
                        theme:'flat'
                    },
                };
                //Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
                this.elements = this.stripe.elements(options);

                //Create and mount the Payment Element
                const paymentElement = this.elements.create('payment');
                paymentElement.mount('#payment-element');
                this.message = "";
        })
        .catch(function(error) {
            console.log('Looks like there was a problem: ', error);
        });
    },
    methods: {
        cancel() {
            if (this.submitting==false) {
                this.message="Cancelling...";
                this.$emit('cancel');
                fetch(this.$root.url+"/payments/"+this.payment_intent_id,{
                    credentials:"include",
                    method:"DELETE",
                })
            }
        },
        async submit() {
            this.submitting = true;
            let elements = this.elements
            this.message="Loading...";
            const { error } = await this.stripe.confirmPayment({
                elements,
                confirmParams: {
                    // Make sure to change this to your payment completion page
                    return_url: process.env.VUE_APP_URL+"/#/orders/"+this.orderid+"/thankyou/",
                },
            });


            // This point will only be reached if there is an immediate error when
            // confirming the payment. Otherwise, your customer will be redirected to
            // your `return_url`. For some payment methods like iDEAL, your customer will
            // be redirected to an intermediate site first to authorize the payment, then
            // redirected to the `return_url`.
            if (error.type === "card_error" || error.type === "validation_error") {
                this.message = error.message;
            } else {
                this.message = "An unexpected error occured.";
            }
            this.submitting = false;
        }
    }
}
</script>
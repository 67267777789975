import { createStore } from 'vuex'

export default createStore({
  state: {
      i:1,
      cart_items: [],
      user: {
          email:"",
          role:"guest"
      }
  },
  mutations: {
      //would need changed to only remove the current item, or items should be joined together
      add (state, item) {
          state.cart_items.push({cart_id:state.i, ...item})
          state.i++;
      },
      replace (state, item) {
        var index = state.cart_items.findIndex((cart_id) => cart_id > item.cart_id);
        state.cart_items[index] = item;
      },
      remove (state, index) {
          state.cart_items.splice(index,1);
      },
      clear (state) {
          state.cart_items = [];
      },
      login (state, user) {
          state.user = user;
      },
      logout (state) {
        state.user = {
            email:"",
            type:"guest"
        }
      }
  },
  actions: {
  },
  modules: {
  }
})

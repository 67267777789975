<template>
    <h2>Create Invoice</h2>
    <form @submit.prevent="submit">
    <p>{{message}}</p>
    <p>Description:<input type="text" v-model="description"/></p>
    <p>Amount:<input type="number" v-model="amount"/></p>
    <p>Due Date:<input type="date" v-model="due_date"/></p>
    <p>Locked:<input type="checkbox" v-model="locked"/></p>
    <button type="submit">Create</button>
     <button type="button" @click="$emit('cancel')">Cancel</button>
    </form>
</template>
<script>
export default {
    props: ['orderid'],
    data() {
        console.log(this.$root.statuses);
        return {
            description: "",
            amount: 0.00,
            due_date: "",
            message: "",
            locked: false
        }
    },
    methods: {
        submit() {
            this.message = "Loading...";
            let timestamp = ((new Date(this.due_date).getTime()+86400000-1000)/1000).toFixed(0);
            console.log(timestamp);
            let url = this.$root.url + "/invoices";
            fetch(url, {
                method:"POST", 
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify({
                    order_id: this.orderid,
                    description: this.description,
                    amount: parseFloat(this.amount),
                    remaining: parseFloat(this.amount),
                    due_date: timestamp,
                    locked: this.locked
                })
            })
            .then((response)=>{
                if (response.ok) {
                   this.message = "";
                   this.description = "";
                   this.due_date = "";
                   this.$emit('addInvoice', response.json());
                } else  {
                    this.message = "Error"; //TODO add message
                }
            })
            .catch((error)=> {
                this.message = "Connection or Server Error";
                console.error('EXCEPTION: ', error)
            });
          
        }
    }
}  
</script>      
     
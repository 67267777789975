<template>
<div class="home">
<h1>Logout</h1>
    <ul>
    <li><p>{{message}}</p></li>
    </ul>
</div>
</template>

<script>
export default {
    data() {
        return {
            message: "Logging you out. You will be redirected automatically.",
        }
    },
    mounted() {
        fetch(this.$root.url+"/logout", {
            method:"GET", 
            credentials: "include",
        })
        .then((response)=>{
            if (response.ok) {
                this.$store.commit('logout');
                this.$router.push({name:'Login'});
            }
        })
        .catch((error) =>{
            this.message = "Connection or Server Error. You have not been logged out.";
            console.error('EXCEPTION: ', error)
        });
        
    }
}
</script>
import { createRouter, createWebHashHistory } from 'vue-router'
import Admin from '../views/admin/Admin.vue'
import AdminItems from '../views/admin/Items.vue'
import AdminCategories from '../views/admin/Categories.vue'
import AdminItem from '../components/admin/Item.vue'
import AdminOrders from '../views/admin/Orders.vue'
import AdminStatuses from '../views/admin/Statuses.vue'
import AdminUsers from '../views/admin/Users.vue'
import AdminOrder from '../views/admin/Order.vue'

import Home from '../views/Home.vue'
import Join from '../views/Join.vue'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import Item from '../components/Item.vue'
import Cart from '../components/Cart.vue'
import Category from '../components/Category.vue'
import Orders from '../views/Orders.vue'
import Order from '../views/Order.vue'
import User from '../components/User.vue'
import ThankYou from '../views/ThankYou.vue'


const routes = [
  {
    path: '/admin',
    component: Admin,
    children: [
      // UserHome will be rendered inside User's <router-view>
      { path: 'categories/', component: AdminCategories, props: true},
      { path: 'items/', component: AdminItems, props: true},
      { path: 'statuses/', component: AdminStatuses, props: true},
      { path: 'orders/', component: AdminOrders, props: true},
      { path: 'orders/:id', component: AdminOrder, props: true},
      { path: 'orders/new/:userid', component: AdminOrder, props: true},
      { path: 'users/', component: AdminUsers, props: true},
      { path: 'users/:id', component: User, props:true },
      { path: 'users/new', component: User, props:true },
      { path: 'items/:id', component: AdminItem, props:true },
    ]
  },
  {
    path: '/join',
    name: 'Join',
    component: Join,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders
  },
  { 
    name: 'Item',
    path: '/items/:id', 
    component: Item,
    props: true
  },
  { 
    path: '/orders/:id', 
    component: Order,
    props: true
  },
  { 
    path: '/orders/:id/thankyou', 
    component: ThankYou,
    props: true,
    beforeEnter: (to) => {
      console.log(to);
      //get rid of stripe query cluttering url bar
      window.location = "/#/orders/"+to.params.id;
    }
  },
  { 
    path: '/categories/:id', 
    component: Category,
    props:true 
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

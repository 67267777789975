
<template>
    <div class="standard">
    <p><router-link :to='{path: "/admin/users/new"}'>New User</router-link></p>
    <table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>User</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="user in users" :key="user.id">
                    <td><router-link :to='{path: "/admin/users/" + user.id}'>{{user.id}}</router-link></td>
                    <td>{{user.email}}</td>
                </tr>
            </tbody>
    </table>
    </div>
</template>

<script>
export default {
  data() {
      return {
          users:[]
      }
  },
   created () {
      fetch(this.$root.url+"/users/",{
          credentials:"include"
      })
      .then(response => response.json())
        .then(data => {
            this.users = data;
        })
        .catch(function(error) {
            console.log('Looks like there was a problem: ', error);
        });
   },
}
</script>
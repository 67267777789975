<template>
    <Login :create="true" />
</template>
<script>
import Login from '@/views/Login.vue'
export default {
    components: {
   Login,
    // ChooseItems
    }
}
</script>
<template>
  <div class="home">
    <h2>Categories</h2>
    <p><button @click="newCategory">New Category</button></p>
    <ol>
        <li v-for="category in categories" :key="category.id"><router-link to='"/admin/category/"+category.id'>{{category.name}}</router-link></li>
    </ol>
  </div>
</template>

<script>
export default {
  name: 'Categories',
  data() {
      let categories = {};
      //convert categories array into heigharcy
        this.$root.categories.forEach(function(category) {
            category.children = [];
            categories[category.id] = category;
        });

        //loop through again to add child references to all parents
        Object.keys(categories).forEach(function(key) {
            let category = categories[key];
            //if there is parent add id to 
            if (category.parent_id) {
                categories[category.parent_id].children.push(category.id);
            }
        });
        categories = this.join_children_to_parents(categories, null);

    return {
      categories: categories
    }
  },
  methods: {
    newCategory() {
      this.$root.c+=1;
      let category = {id: this.$root.c, name:""};
      this.$root.categories.push(category);
      this.$router.push({path:'/admin/categories/'+category.id})
    },
    join_children_to_parents(family, parent) {
            let that = this;
            let parents = {};
            if (!parent) {
                parents = family
            } else {
                parents[parent.id] = parent;
            }
            Object.keys(parents).forEach(function(key) {
                let parent = family[key]
                if (!parent) return;
                
                parent.children.forEach(function(child_id){
                    let child = family[child_id];
                    if (!child) {
                        family[child_id]= null;
                        return
                    }
                    if (child.children)
                        that.join_children_to_parents(family, child)

                    //Remove from top level
                    family[child_id]= null;
                    
                });
            })
            return family;
        }
  }
}
</script>
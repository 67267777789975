<template>
<div class="home">
<div v-if="!edit">
    <h1>View</h1>
    <h2>{{user.name}}</h2>
    <h3>{{user.email}}</h3>
    <h3>{{user.phone}}</h3>
    <p><a @click="edit=true">Edit</a></p>
    <p><router-link :to='{path: "/admin/orders/new/"+user.id}'>New Order</router-link></p>
</div>
<div v-else>
    <h1 v-if="user.id">Edit User</h1>
    <h1 v-else>Create User</h1>
    <form @submit.prevent="submit">
        <ul>
            <li><p>{{message}}</p></li>
            <li><label for="user_name">Name:</label><input type="text" id="user_name" v-model="editUser.name" required/></li>
            <li><label for="user_email">Email:</label><input type="email" id="user_email" v-model="editUser.email" required/></li>
            <li><label for="user_phone">Phone:</label><input type="tel" id="user_phone" v-model="editUser.phone" required/></li>
            <li v-if="id"><button type="submit">Save</button></li>
            <li v-else><button type="submit">Create</button></li>
        </ul>
    </form>
    <p v-if="user.id"><a @click="edit=false">Cancel</a></p>
</div>
</div>
</template>

<script>
export default {
    props: ['id'],
    data() {
        return {
            edit: false,
            user:{},
            message:"",
            editUser: {
                name:"",
                email:"",
                phone:""
            },
        }
    },
    created () {
        if (this.id) {
            console.log(this.id);
        fetch(this.$root.url+"/users/"+this.id,{
            credentials:"include"
        })
        .then(response => response.json())
            .then(data => {
                this.user = data;
                this.editUser = {...this.user};
            })
            .catch(function(error) {
                console.log('Looks like there was a problem: ', error);
            });
        } else {
            this.edit=true;
        }
    },
    methods: {
        submit() {
            this.message="Loading...";
            if (this.user.id) {   
                fetch(this.$root.url+"/users/"+this.user.id, {
                    method:"PUT", 
                    credentials: "include",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body:JSON.stringify(this.editUser)
                })
                .then((response)=>{
                    if (response.ok) {
                        this.edit=false;
                        this.message= "";
                    } else  {
                        this.message = "Error updating.";
                    }
                })
                .catch(error =>{
                    this.message = "Connection or Server Error";
                    console.error('EXCEPTION: ', error)
                });
            } else {
                fetch(this.$root.url+"/users/", {
                    method:"POST", 
                    credentials: "include",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body:JSON.stringify(this.editUser)
                })
                .then(response => response.json())
                .then(data => {
                    this.user = data;
                    this.edit = false;
                    this.message="";
                })
                .catch(error =>{
                    this.message = "Connection or Server Error";
                    console.error('EXCEPTION: ', error)
                });
            }
        }
    }
}
</script>
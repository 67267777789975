<template>
<div>
    <div v-if="type!='part'">
        <h1>{{ item.name }}</h1>
        <p>Description: {{item.description}}</p>
        <p>Quantity: {{item.quanity}}</p>
    </div>
    <div v-else>
        <p>{{ item.name}}</p>
    </div>
    <ol>
        <li v-for="option in item.options" :key="option.id">
            {{ option.name }}
            <div v-if="option.type=='text'">
                <TextOption :name="option.name" v-model:text="option.value"></TextOption>
            </div>
            <div v-if="option.type=='choose'">
                <ChooseItems :name="option.name" :fid="option.filter_id"  :limit="option.limit" v-model:items="option.items"></ChooseItems>
            </div>
            <div v-if="option.type=='add'">
                <div v-for="(sub,index) in option.items" :key="index">
                    <Item :id="sub.id" :type="'part'" v-model:existing="option.items[index]"></Item>
                     <button type="button" v-on:click="removeItemOption(option, index)">Remove</button>
                </div>
                 <button type="button" v-on:click="addItemOption(option, option.filter_id)">Add {{option.name}}</button>
            </div>
        </li>
    </ol>
    <div v-if="type!='part'">
    <div v-if="!cart_id">
    <div v-for="fulfillment in fulfillments" :key="fulfillment.id">
    <button @click="add(item, fulfillment.id)">Add to Cart - {{fulfillment.name}}</button>
    </div>
    </div>
    <div v-else>
    <button @click="replace(item)">Update Cart</button>
    </div>
    </div>
</div>
</template>
<script>


import ChooseItems from '@/components/ChooseItems.vue'
import TextOption from '@/components/TextOption.vue'

export default {
    props: ['cart_id', 'id', 'type', 'existing'],
    data() {
        var item = {};
        if (this.cart_id) {
            item = this.$store.state.cart_items.find(({cart_id})=> cart_id == this.cart_id  )
        }
        else if (this.existing && this.existing.name) {
            item = this.existing
        }
        else {
            //Create new items and new item options
            item= {...this.$root.items.find(({id})=>id==this.id)};
            let options= this.$root.options.filter((option)=>this.$root.item_options.filter(({item_id})=> item_id==this.id).some((item_option)=>item_option.option_id==option.id));
            item.options = {};
            options.forEach(function(option) {
                item.options[option.id] = { ...option, items: [], chosen:[], value:""}; //spread happens here
            });
        }
        //item: this.$root.items.find(item=> this.$root.category_items.filter(({category_id})=>category_id==this.id).some(category_item => item.id== category_item.item_id))
        var categories = this.$root.categories.filter(category=> this.$root.category_items.filter(({item_id})=>item_id==item.id).some(category_item => category_item.category_id == category.id));
        var category_fulfillments = this.$root.category_fulfillments.filter(category_fulfillment => 
                categories.some(category=> category.id == category_fulfillment.category_id));
        var fulfillments = this.$root.fulfillments.filter(fulfillment => 
            category_fulfillments.some(category_fulfillment => category_fulfillment.fulfillment_id == fulfillment.id));            
        return {
            item: item,
            fulfillments: fulfillments
        }
    },
    emits: ['update:existing'],
    watch:
    {
        item:
        {
            handler: function (){
                if (this.type=="part") {
                    console.log("emitting");
                    this.$emit('update:existing',this.$data.item);
                }
            },
            deep:true
        },
    },
    methods: {
        add(item) {
            this.$store.commit('add', item);
            this.$router.push({name:'Cart'})
        },
        replace(item) {
            this.$store.commit('replace', item);
            this.$router.push({name:'Cart'})
        },
        addItemOption: function(option,id) {
            option.items.push({id:id})
        },
        removeItemOption: function(option, index) {
            option.items.splice(index,1);
        }
    },
    components: {
        ChooseItems,
        TextOption
    }
}
</script>
<template>  
<div>
    <p v-if="limit">Limit {{limit}}</p>
    <div v-for="item in available_items" :key="item.id">
    <input type="checkbox" :id="item.id" :value="item" v-model="updated_chosen" />
    <label :for="item.id">{{item.name}}</label>
    </div>
    <p></p>
</div>
</template>
<script>
export default {
    name: 'ChooseItems',
    props: ['fid', 'name', 'items', 'limit'],
    data() {
        var available_items;
        if (this.fid ==2) {
            available_items= this.$root.categories;
        } else {
            available_items= this.$root.items.filter(item=> this.$root.category_items.filter(({category_id})=>category_id==this.fid).some(category_item => item.id== category_item.item_id))
        }
        return {
            available_items: available_items,
            updated_chosen: this.items
        }
    },
    emits: ['update:items'],
    watch:
    {
        updated_chosen(newVal, oldVal)
        {
            if(newVal !== oldVal) {
                if (this.limit) {
                    var l = this.$data.updated_chosen.length
                    if (l>this.limit) this.$data.updated_chosen.splice(l-1,1);
                }
                this.$emit('update:items',this.$data.updated_chosen);
            }
        }
    }
}
</script>
<template>
<div v-if="order" class="standard">
    <h1>Order - {{order.order.description}}</h1>
    <p>Ordered on {{dateformat(order.order.created)}}</p>
    <p>Ordered by {{order.user.email}}</p>
    <div v-if="make_payment">
        <Payment @cancel="make_payment=false" :amount="this.amount" :orderid="this.id"/>
    </div>
    <div v-else>
        <h2>Invoices</h2>
        <ol>
            <li v-for="invoice in order.invoices" :key="invoice.id">
                <h3 v-if="!invoice.remaining">Paid - {{invoice.description}} - {{formatter.format(invoice.amount)}}</h3>
                <div v-if="invoice.remaining">
                    <h3>{{invoice.description}}</h3>
                    <div v-if="invoice.locked">
                        <p>{{formatter.format(invoice.remaining)}} - Not available to be paid at this time.</p>
                    </div>
                    <div v-else>
                        <p>{{formatter.format(invoice.amount)}} - Due on {{dateformat(invoice.due_date)}}</p>
                        <p v-if="invoice.remaining!=invoice.amount">Paid: {{formatter.format(invoice.amount-invoice.remaining)}}</p>
                        <button class="smaller" v-if="!invoice.added" @click="amount+=invoice.remaining;invoice.added=true">Add to payment.</button>
                        <button class="smaller" v-if="invoice.added" @click="amount-=invoice.remaining;invoice.added=false">Remove</button>
                    </div>
                </div>
            </li>
        </ol>
        <button v-if="amount>0" @click="make_payment=true">Pay {{formatter.format(amount)}}</button>
        <button v-if="amount==0 && payable" @click="{}">Pay {{formatter.format(amount)}}</button>
    
        <div v-if="order.payments.length>0">
        <h2>Payments</h2>
        <ol>
            <li v-for="payment in order.payments" :key="payment.id">
                <h3>{{formatter.format(payment.amount)}}</h3>
                <p>{{payment.description}}</p>
                <div v-if="payment.paid">
                    <p>Paid on {{ dateformat(payment.paid_date) }}</p>
                </div>
            </li>
        </ol>
        </div>
        <h2>Updates</h2>
        <ol>
            <li v-for="update in order.updates" :key="update.id">
                <h3 v-if="statuses[update.status_id]">{{dateformat(update.created)}} - {{update.title}}</h3>
            </li>
        </ol>
    </div>
</div>
</template>
<style>
    .smaller {
        padding:5px;
        width: auto;
    }
</style>
<script>
import Payment from '@/components/Payment.vue'
export default {
    props:['id'],
    data() {
        
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });
        return {
            make_payment: false,
            payable: false,
            amount: 0,
            formatter: formatter,
            order:false,
            statuses: this.$root.statuses,
        }
    },
    methods: {
        dateformat(timestamp) {
            let date = new Date(timestamp*1000);
            return date.toLocaleDateString();
        },
    },
    created () {
        let that = this;
        fetch(this.$root.url+"/orders/"+this.id,{
            credentials:"include"
        })
        .then(response => response.json())
        .then(data => {
            this.order = data;
            this.order.invoices.forEach(invoice=>{
                if (invoice.remaining>0) {
                    that.payable=true;
                    console.log(invoice.remaining);
                }
            });
        })
        .catch(function(error) {
            console.log('Looks like there was a problem: ', error);
        });
    },
    components: {
        Payment
    }
}
</script>
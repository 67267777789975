<template>
    <input type="text" v-model="updated_text"/>
</template>
<script>
export default {
    props: ['name', 'text'],
    data() {
        return {
            updated_text:this.text
        }
    },
    emits: ['update:text'],
    watch:
    {
        updated_text(newVal, oldVal)
        {
            if(newVal !== oldVal) this.$emit('update:text', newVal);
        }
    },
}  
</script>      
     
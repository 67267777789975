
<template>
    <div class="orders">
        <p>Click order title to open.</p>
        <div v-for="order in orders" :key="order.id">
            <h2 v-if="users"><router-link :to='{path: "/admin/orders/" + order.id}'>{{order.id}} - {{order.description}}</router-link></h2>
            <h2 v-if="!users"><router-link :to='{path: "/orders/" + order.id}'>{{order.description}}</router-link></h2>
            <p v-if="users">{{order.user_email}}</p>
            <p>{{order.update_title}} on {{(new Date(order.update_created *1000).toLocaleDateString())}}</p>                    
        </div>
    </div>
</template>

<script>
export default {
  name: 'Orders',
  props: ['orders','users'],
  data() {
      return {
          statuses:this.$root.statuses
      }
  },
}


</script>
<template>
    <h1>Shopping Cart</h1>
    <div>
        <div v-for="item in items" :key="item.cart_id"> 
            <CartPart :item="item"></CartPart>
        </div>
        <button @click="clear">Empty</button>
        <p>Name: <input type="text" v-model="name" />
        <button @click="order">Order</button></p>
    </div>
</template>
<script>

import CartPart from '@/components/CartPart.vue'
 
export default {
    name: 'Cart',
    data() {
        return {
            name:""
        }
    },
    computed: {
        items(){ return this.$store.state.cart_items}
    },
    methods: {
        clear() {
            this.$store.commit('clear');
        },
        order() {
            let order_id = this.$root.order_i;
            this.$root.order_i++
            this.$root.orders.push({id: order_id, customer:this.name})
            let that = this;
            this.items.forEach(function (item) {
                that.addItem(item, order_id, null, null, null);
            });
            this.$router.push({path:"Orders"})
            console.log(this.$root.order_items)
        },
        addItem(item, order_id, parent_id, option_id, value) {
            let that = this;
            let order_item_id = this.$root.order_item_i;
            this.$root.order_item_i++;
            let item_id = null
            if (item) {
                item_id = item.id;                
            }
            this.$root.order_items.push({
                id: order_item_id, 
                order_id: order_id, 
                item_id: item_id,
                parent_id: parent_id, 
                option_id: option_id, 
                value:value
            })
            if (item && item.options) {
                Object.keys(item.options).forEach(function(key) {
                    let option = item.options[key];
                    let parent_id = order_item_id;
                    if (option.type == "text")
                        that.addItem(null, order_id, parent_id, option.id, option.value)
                    if (option.type == "choose" || option.type=="add")  {
                        option.items.forEach(function (item) {
                            that.addItem(item, order_id, parent_id, option.id, null);
                        })
                    }
                });
            }
        }
    },
    components :{
        CartPart
    }
}
</script>


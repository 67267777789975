<template>
  <div class="home">
    <div id="logo">
      <img
      src="/assets/logo2.svg"
      alt="Logo of a falcon holding a kitchen cabinet with its talons."
      />
    </div>
    <p>Located in the Fort Wayne metro, Falcon Cabinets believes everyone should be able to afford functional, 
    quality cabinets. If you need kitchen, bath, or other cabinets please contact us
    to get a quote or schedule an in-home meeting.</p>
    <p>260-797-7699 | hello@falconcabinets.co</p>
  </div>
  
</template>
<style>


  #logo {
    margin:auto;
    width: 280px;
  }



</style>
<script>
 //#DD6F1C
// @ is an alias to /src
    //<ChooseItems :name="HomeCategories" :fid="2" :chosen="[]" ></ChooseItems>
//import Categories from '@/components/Categories.vue'
//import ChooseItems from '@/components/ChooseItems.vue'
export default {
  name: 'Home',
  data() {
    // var filter= 1;
    // var categories = this.$root.categories.filter(
    //   category=> this.$root.filter_selection.filter(
    //     ({filter_id})=>filter_id==filter
    //   ).some(filter => category.id== filter.category_id)
    // )
    // return {
    //   categories: categories
    // }
  },
  components: {
   // Categories,
   // ChooseItems
  }
}
</script>
